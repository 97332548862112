import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "browser-support",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#browser-support",
        "aria-label": "browser support permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Browser Support`}</h1>
    <p>{`Popper supports modern versions of Chrome, Firefox, Safari, Edge. IE11 works
with some polyfills. Additionally, any Chromium-based browser, such as Opera,
Beaker, etc should be supported.`}</p>
    <x-ad />
    <h2 {...{
      "id": "ie11",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ie11",
        "aria-label": "ie11 permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IE11`}</h2>
    <p>{`IE11 (and older browsers in general) require polyfills to work. The simplest way
to make Popper work is to use the following polyfill service:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://polyfill.io/v3/polyfill.min.js?features=Array.prototype.find,Promise,Object.assign`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Browsers that don't need the polyfills won't be burdened with the JS bundle
size.`}</p>
    <h3 {...{
      "id": "support-note",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#support-note",
        "aria-label": "support note permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support note`}</h3>
    <p>{`If you need to support old browsers, ensure you `}<strong parentName="p">{`lock`}</strong>{` Popper's version to a
specific number when using the CDN. On new releases, we may add more modern
functions that require new polyfills, or positioning behavior may break in IE11
since it is completely untested. We don't plan to officially support IE11, but
welcome PRs to fix issues. For the most part, IE11 seems to work without much
trouble, but there may be edge cases in some scenarios.`}</p>
    <h2 {...{
      "id": "safari",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#safari",
        "aria-label": "safari permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Safari`}</h2>
    <p>{`Safari has unfortunate quirks regarding updating the position of DOM elements.
We have filed WebKit bugs for these, so hopefully they will be fixed in the
future.`}</p>
    <h3 {...{
      "id": "lag",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#lag",
        "aria-label": "lag permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lag`}</h3>
    <p>{`On macOS and iOS, when the popper is prevented from overflowing the root
viewport boundary, it can appear to lag behind while scrolling (not 1:1
in-sync). For iOS this also happens in scrolling containers.`}</p>
    <p>{`We recommend one of these options for Safari only:`}</p>
    <ul>
      <li parentName="ul">{`Setting `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rootBoundary`}</code>{` to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`"document"`}</code>{` for the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`preventOverflow`}</code>{` modifier`}</li>
      <li parentName="ul">{`Hiding the popper upon scrolling`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://bugs.webkit.org/show_bug.cgi?id=206228"
      }}>{`WebKit bug report`}</a></p>
    <h3 {...{
      "id": "fixed-strategy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fixed-strategy",
        "aria-label": "fixed strategy permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fixed strategy`}</h3>
    <p>{`In the latest version of macOS Safari, when elastic overscroll (rubber-banding
effect) is in progress, fixed elements will detach from the reference element
because they no longer stick `}<strong parentName="p">{`including`}</strong>{` the elastic overscroll – which Safari
reports scroll to include. On iOS, this is not an issue.`}</p>
    <p>{`In addition, while scrolling the document, a slight lag as mentioned above also
becomes present because the popper needs to be continuously updated while
scrolling, which Safari is not good at. Ideally this strategy should only be
used when the reference element is fixed, and not to "break" the popper out of a
clipping container context.`}</p>
    <p><a parentName="p" {...{
        "href": "https://bugs.webkit.org/show_bug.cgi?id=206227"
      }}>{`WebKit bug report`}</a></p>
    <h2 {...{
      "id": "ie10-and-older",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ie10-and-older",
        "aria-label": "ie10 and older permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IE10 and older`}</h2>
    <p>{`Browsers older than 2013 (including Android 4.4) will never be supported since
they are becoming increasingly obsolete and add a lot of complexity (runtime and
bundle) costs to the library.`}</p>
    <p>{`We encourage any Internet Explorer user to upgrade to
`}<a parentName="p" {...{
        "href": "https://www.microsoft.com/edge"
      }}>{`Microsoft Edge`}</a>{`, their most recent versions are
based on Chromium, allowing to render all the modern websites without issues,
but still provide a compatibility mode to render legacy websites designed to
work with Internet Explorer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      